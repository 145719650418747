(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/bet-proposal/assets/javascripts/helpers/get-outcome-label.js') >= 0) return;  svs.modules.push('/components/marketplace/bet-proposal/assets/javascripts/helpers/get-outcome-label.js');
'use strict';

const getOutcomeLabel = _ref => {
  let {
    description,
    eventTypeId,
    outcomeNumber
  } = _ref;
  if (eventTypeId === 4) {
    return outcomeNumber === 1 ? 'ö' : 'u';
  }
  return description;
};
setGlobal('svs.components.marketplace.betProposal.helpers.getOutcomeLabel', getOutcomeLabel);

 })(window);