(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/bet-proposal/assets/javascripts/bet-proposal-bomb-row.js') >= 0) return;  svs.modules.push('/components/marketplace/bet-proposal/assets/javascripts/bet-proposal-bomb-row.js');
'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  BetProposalBetButton
} = svs.components.marketplace.betProposal;
const {
  ReactBadge: Badge
} = svs.ui;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_MD
} = svs.components.lbUtils.useMediaQuery;
const BetProposalBombRow = _ref => {
  let {
    participantType,
    onToggleOutcome,
    eventComment,
    matchParticipants,
    outcomes,
    groupId,
    isDisabled,
    isSelected,
    eventNumber,
    eventTypeId,
    selectedOutcomes,
    showSmall
  } = _ref;
  const className = 'qa-bet-proposal-row draw-list-container'.concat(isSelected ? ' current-match' : '');
  const isTypePlayer = participantType === 'Player';
  const isSmall = useMediaQuery(breakpoints.down(BREAKPOINT_MD));
  if (!outcomes || outcomes.length < 1) {
    return null;
  }
  const handleSelect = (eventNumber, outcomeNumber, participantNumber) => {
    if (!isDisabled && onToggleOutcome) {
      const outcome = outcomes[participantNumber].filter(outcome => outcome.outcomeNumber === outcomeNumber).pop();
      onToggleOutcome(_objectSpread(_objectSpread({}, outcome), {}, {
        eventNumber,
        groupId,
        participantNumber
      }));
    }
  };
  return React.createElement("div", {
    className: "bomben-match".concat(showSmall ? ' bomben-match-small' : '')
  }, React.createElement("div", {
    className: "coupon-event-number"
  }, React.createElement("div", null, React.createElement(Badge, {
    branding: "bomben",
    className: "roundbadge"
  }, eventNumber)), (isSmall || showSmall) && React.createElement("div", {
    className: "coupon-event-name"
  }, matchParticipants[0].mediumName, ' - ', matchParticipants[1].mediumName)), matchParticipants.map((participant, key) =>
  React.createElement("li", {
    className: className,
    key: "".concat(key, "_").concat(participant)
  }, React.createElement("div", {
    className: "coupon-event-row coupon-event-row-bomben"
  }, !(isSmall || showSmall) && React.createElement("div", {
    className: "coupon-event-description"
  }, React.createElement("p", null, ' ', participant.mediumName), isTypePlayer && React.createElement("p", {
    className: "coupon-event-participants fc-grey-500 coupon-event-extra"
  }, participant.h2HName), React.createElement("p", {
    className: "fc-grey-400 coupon-extra"
  }, eventComment)), React.createElement("div", {
    className: "coupon-event-outcomes qa-coupon-event-outcomes"
  }, outcomes[key].map((outcome, index, arr) => {
    const output = [];
    output.push(React.createElement(BetProposalBetButton, {
      eventNumber: eventNumber,
      eventTypeId: eventTypeId,
      isDisabled: isDisabled,
      key: outcome.outcomeNumber,
      onSelect: () => {
        handleSelect(eventNumber, outcome.outcomeNumber, key);
      },
      outcome: _objectSpread(_objectSpread({}, outcome), {}, {
        isSelected: selectedOutcomes[key] && selectedOutcomes[key][outcome.outcomeNumber]
      })
    }));
    if (index === 0 && arr.length === 2) {
      output.push(React.createElement(BetProposalBetButton, {
        eventNumber: 0,
        eventTypeId: 0,
        isDisabled: true,
        key: "disabled",
        outcome: {
          description: '',
          outcomeNumber: -1,
          isSelected: false
        }
      }));
    }
    return output;
  }))))));
};
BetProposalBombRow.defaultProps = {
  eventTypeId: null,
  isDisabled: false,
  isSelected: false,
  selectedOutcomes: [[false, false, false, false, false, false, false, false, false, false, false], [false, false, false, false, false, false, false, false, false, false, false]]
};
setGlobal('svs.components.marketplace.betProposal.BetProposalBombRow', BetProposalBombRow);

 })(window);