(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/bet-proposal/assets/javascripts/bet-proposal-fulltraff-row.js') >= 0) return;  svs.modules.push('/components/marketplace/bet-proposal/assets/javascripts/bet-proposal-fulltraff-row.js');
'use strict';

const {
  useCallback
} = React;
const {
  BetProposalBetButton
} = svs.components.marketplace.betProposal;
const {
  ReactBadge: Badge
} = svs.ui;
const BetProposalFulltraffRow = _ref => {
  let {
    onToggleOutcome,
    matchParticipants,
    groupId,
    isDisabled,
    eventNumber,
    eventTypeId,
    outcomes,
    selectedOutcomes,
    showSmall
  } = _ref;
  const handleSelect = useCallback((eventNumber, goals) => !isDisabled && onToggleOutcome && onToggleOutcome({
    outcomeNumber: goals,
    eventNumber,
    groupId
  }), [groupId, onToggleOutcome]);
  if (!outcomes || outcomes.length < 1) {
    return null;
  }
  return React.createElement("li", {
    className: "fulltraff-match".concat(showSmall ? ' fulltraff-match-small' : '')
  }, React.createElement("div", {
    className: "coupon-event-number"
  }, React.createElement("div", null, React.createElement(Badge, {
    branding: "fulltraff",
    className: "roundbadge"
  }, eventNumber)), React.createElement("div", {
    className: "coupon-event-name notranslate"
  }, matchParticipants[0].mediumName, ' - ', matchParticipants[1].mediumName)), React.createElement("div", {
    className: "coupon-event-row coupon-event-row-fulltraff"
  }, React.createElement("div", {
    className: "coupon-event-outcomes qa-coupon-event-outcomes"
  }, outcomes.map(_ref2 => {
    let {
      description,
      outcomeNumber
    } = _ref2;
    return React.createElement(BetProposalBetButton, {
      eventNumber: eventNumber,
      eventTypeId: eventTypeId,
      isDisabled: isDisabled,
      key: "key-".concat(eventNumber, "-").concat(outcomeNumber),
      onSelect: () => handleSelect === null || handleSelect === void 0 ? void 0 : handleSelect(eventNumber, outcomeNumber),
      outcome: {
        description,
        outcomeNumber,
        isSelected: selectedOutcomes[outcomeNumber]
      }
    });
  }))));
};
BetProposalFulltraffRow.defaultProps = {
  eventTypeId: null,
  isDisabled: false,
  selectedOutcomes: {}
};
setGlobal('svs.components.marketplace.betProposal.BetProposalFulltraffRow', BetProposalFulltraffRow);

 })(window);