(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/bet-proposal/assets/javascripts/bet-proposal-container.js') >= 0) return;  svs.modules.push('/components/marketplace/bet-proposal/assets/javascripts/bet-proposal-container.js');
'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  selectors
} = svs.components.marketplaceData;
const {
  BetProposalComponent
} = svs.components.marketplace.betProposal;
const {
  fetchBetProposal
} = svs.components.marketplaceData.betProposal.actions;
const {
  getProdName
} = svs.components.lbUtils.sportProducts;
const {
  getRowId: getBetProposalRowId
} = svs.components.marketplace.helpers;
const mapStateToProps = (state, _ref) => {
  var _state$Races$draw$rac;
  let {
    groupId,
    activatedDrawId
  } = _ref;
  const game = selectors.games.getGame(state, {
    activatedDrawId,
    groupId
  }) || {};
  const productName = getProdName(game.productId);
  const events = selectors.draws.getDrawEvents(state, {
    drawNumber: game.drawNumber,
    productName
  }) || [];
  const draw = selectors.draws.getDraw(state, {
    drawNumber: game.drawNumber,
    productName
  }) || {};
  const rowId = getBetProposalRowId(productName, game.drawNumber, groupId);
  const {
    isCreating,
    isUpdating,
    data: createData,
    createError,
    updateError
  } = _objectSpread(_objectSpread({}, state.CreateBetProposal[rowId] || {}), state.CreateBetProposal[activatedDrawId] || {});
  const {
    loading: isLoading,
    data,
    error
  } = state.BetProposal[activatedDrawId] || {};

  const selectedOutcomes = isUpdating || updateError ? createData : data;
  return {
    didErrorToCreate: Boolean(createError),
    didErrorToUpdate: Boolean(updateError),
    drawNumber: game.drawNumber,
    activatedDrawId,
    events,
    didErrorToFetch: Boolean(error),
    selectedOutcomes,
    isCreating,
    isUpdating,
    isLoading,
    raceId: draw === null || draw === void 0 ? void 0 : draw.raceId,
    participants: (state === null || state === void 0 ? void 0 : state.Races) && (state === null || state === void 0 || (_state$Races$draw$rac = state.Races[draw === null || draw === void 0 ? void 0 : draw.raceId]) === null || _state$Races$draw$rac === void 0 ? void 0 : _state$Races$draw$rac.participants),
    productName
  };
};
const mapDispatchToProps = dispatch => ({
  onFetchBetProposal: (activatedDrawId, groupId, productName) => dispatch(fetchBetProposal(activatedDrawId, groupId, productName))
});
setGlobal('svs.components.marketplace.betProposal.BetProposalContainer', ReactRedux.connect(mapStateToProps, mapDispatchToProps)(BetProposalComponent));

 })(window);