(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/bet-proposal/assets/javascripts/bet-proposal.js') >= 0) return;  svs.modules.push('/components/marketplace/bet-proposal/assets/javascripts/bet-proposal.js');
'use strict';

const {
  BetProposalPreview
} = svs.components.marketplace.betProposal;
class BetProposal extends React.Component {
  componentDidMount() {
    if (!(this.props.isCreating || this.props.isLoading)) {
      this.props.onFetchBetProposal(this.props.activatedDrawId, this.props.groupId, this.props.productName);
    }
  }
  render() {
    const {
      didErrorToFetch,
      events,
      selectedOutcomes,
      isCreating,
      isLoading,
      isUpdating,
      didErrorToCreate,
      didErrorToUpdate,
      participants,
      raceId,
      productName,
      drawNumber
    } = this.props;
    const hasSelectedOutcomes = Object.keys(selectedOutcomes || {}).some(event => Object.keys(selectedOutcomes[event]).some(outcome => Boolean(selectedOutcomes[event][outcome])));
    const hasEvents = events.length > 0;
    return React.createElement("div", {
      className: "margin-top-2 margin-bottom-2"
    }, isLoading && React.createElement("div", {
      className: "f-center"
    }, React.createElement("span", {
      className: "f-300 margin-top-1"
    }, "H\xE4mtar prelimin\xE4r rad...")), didErrorToFetch && !selectedOutcomes && React.createElement("div", {
      className: "f-center"
    }, React.createElement("span", {
      className: "f-300 fc-red margin-top-1"
    }, "Misslyckades att h\xE4mta prelimin\xE4r rad")), (hasEvents || participants) && hasSelectedOutcomes && React.createElement("div", {
      className: "bet-proposal-preview-container bet-proposal-preview-container-".concat(productName)
    }, React.createElement("span", {
      className: "f-bold f-600 block margin-bottom-1"
    }, "Radskiss"), React.createElement("span", {
      className: "f-400 margin-bottom-1 block"
    }, "T\xE4nk p\xE5 att radskissen inte beh\xF6ver st\xE4mma \xF6verens med de rader som l\xE4mnas in."), React.createElement(BetProposalPreview, {
      didErrorToCreate: didErrorToCreate,
      didErrorToUpdate: didErrorToUpdate,
      drawNumber: drawNumber,
      events: events,
      isCreating: isCreating,
      isUpdating: isUpdating,
      participants: participants,
      productName: productName,
      raceId: raceId,
      selectedOutcomes: selectedOutcomes
    })));
  }
}
setGlobal('svs.components.marketplace.betProposal.BetProposalComponent', BetProposal);

 })(window);