(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/bet-proposal/assets/javascripts/bet-proposal-racing-row.js') >= 0) return;  svs.modules.push('/components/marketplace/bet-proposal/assets/javascripts/bet-proposal-racing-row.js');
'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  BetProposalBetButton
} = svs.components.marketplace.betProposal;
const BetProposalRacingRow = _ref => {
  let {
    driverName,
    isSelected,
    horseName,
    outcomes,
    participantNumber,
    onToggleOutcome,
    eventNumber,
    eventTypeId,
    isDisabled,
    isCanceled,
    selectedOutcomes,
    groupId
  } = _ref;
  const className = 'qa-bet-proposal-row draw-list-container'.concat(isSelected ? ' current-match' : '');
  const handleSelect = onToggleOutcome && (_ref2 => {
    let {
      eventNumber,
      outcomeNumber
    } = _ref2;
    const outcome = outcomes.filter(outcome => outcome.outcomeNumber === outcomeNumber).pop();
    onToggleOutcome(_objectSpread(_objectSpread({}, outcome), {}, {
      eventNumber,
      groupId
    }));
  });
  if (outcomes.length < 1) {
    return React.createElement("div", null, "N\xE5got gick fel, vi kan inte visa radskissen");
  }
  return React.createElement("li", {
    className: className
  }, React.createElement("div", {
    className: "coupon-event-row".concat(isCanceled ? ' coupon-event-row-canceled' : '')
  }, React.createElement("div", {
    className: "coupon-event-number"
  }, participantNumber), React.createElement("div", {
    className: "coupon-event-description"
  }, React.createElement("p", null, horseName), React.createElement("p", {
    className: "fc-grey-400 coupon-extra"
  }, driverName)), React.createElement("div", {
    className: "coupon-event-outcomes"
  }, outcomes.map((outcome, index, arr) => {
    const output = [];
    output.push(React.createElement(BetProposalBetButton, {
      eventNumber: eventNumber,
      eventTypeId: eventTypeId,
      isDisabled: isDisabled || isCanceled,
      key: outcome.outcomeNumber,
      onSelect: handleSelect,
      outcome: _objectSpread(_objectSpread({}, outcome), {}, {
        isSelected: selectedOutcomes[outcome.outcomeNumber]
      })
    }));
    if (index === 0 && arr.length === 2) {
      output.push(React.createElement(BetProposalBetButton, {
        eventNumber: 0,
        eventTypeId: 0,
        isDisabled: true,
        key: "disabled",
        outcome: {
          description: '',
          outcomeNumber: -1,
          isSelected: false
        }
      }));
    }
    return output;
  }))));
};
BetProposalRacingRow.defaultProps = {
  selectedOutcomes: {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false
  }
};
setGlobal('svs.components.marketplace.betProposal.BetProposalRacingRow', BetProposalRacingRow);

 })(window);