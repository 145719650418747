(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/bet-proposal/assets/javascripts/bet-proposal-create.js') >= 0) return;  svs.modules.push('/components/marketplace/bet-proposal/assets/javascripts/bet-proposal-create.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  BetProposalRow,
  RowCounter,
  BetProposalBombRow,
  BetProposalFulltraffRow,
  BetProposalRacingRow
} = svs.components.marketplace.betProposal;
const {
  Button
} = svs.ui;
const {
  fetchSingleDrawAction
} = svs.components.marketplaceData.draws.actions;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  useEffect
} = React;
const {
  resolveProductId
} = svs.utils.products;
const {
  BOMBEN,
  FULLTRAFF,
  RACING
} = svs.utils.products.productIds;
const {
  RacePickerItemLight
} = svs.racing;
const BetProposalCreate = _ref => {
  var _useSelector;
  let {
    drawNumber,
    events,
    selectedOutcomes,
    toggleOutcome,
    onClearRow: handleClearRow,
    isDisabled,
    isLoading,
    productName,
    raceId,
    showSmall
  } = _ref;
  const dispatch = useDispatch();
  const productId = resolveProductId(productName);
  const race = useSelector(state => state.Races[raceId]);
  const meeting = useSelector(state => race ? state.Meetings[race.meetingId] : undefined);
  const draw = useSelector(state => state.Draws[drawNumber]);
  const participants = (_useSelector = useSelector(state => state.Races[raceId])) === null || _useSelector === void 0 ? void 0 : _useSelector.participants;
  useEffect(() => {
    if (race && (!participants || participants.length <= 0)) {
      dispatch(fetchSingleDrawAction(RACING, drawNumber));
    }
  }, [dispatch, drawNumber, participants, race]);
  const renderRows = () => events === null || events === void 0 ? void 0 : events.map(event => React.createElement(BetProposalRow, {
    eventComment: event.eventComment,
    eventDescription: event.eventDescription,
    eventNumber: event.eventNumber,
    eventTypeId: event.eventTypeId,
    isDisabled: isDisabled,
    key: event.eventNumber,
    onToggleOutcome: toggleOutcome,
    outcomes: event.outcomes,
    participantType: event.participantType,
    selectedOutcomes: selectedOutcomes[event.eventNumber]
  }));
  const renderRowsBomben = () => events === null || events === void 0 ? void 0 : events.map(event => React.createElement(BetProposalBombRow, {
    eventComment: event.eventComment,
    eventDescription: event.eventDescription,
    eventNumber: event.eventNumber,
    eventTypeId: event.eventTypeId,
    isDisabled: isDisabled,
    key: event.eventNumber,
    matchParticipants: event.matchParticipants,
    onToggleOutcome: toggleOutcome,
    outcomes: event.outcomes,
    participantType: event.participantType,
    selectedOutcomes: selectedOutcomes[event.eventNumber],
    showSmall: showSmall
  }));
  const renderRowsFulltraff = () => events === null || events === void 0 ? void 0 : events.map(event => React.createElement(BetProposalFulltraffRow, {
    eventComment: event.eventComment,
    eventDescription: event.eventDescription,
    eventNumber: event.eventNumber,
    eventTypeId: event.eventTypeId,
    isDisabled: isDisabled,
    key: event.eventNumber,
    matchParticipants: event.matchParticipants,
    onToggleOutcome: toggleOutcome,
    outcomes: event.outcomes,
    participantType: event.participantType,
    selectedOutcomes: selectedOutcomes[event.eventNumber],
    showSmall: showSmall
  }));
  const renderRacing = () => participants && participants.length > 0 ? participants.map(participant => React.createElement(BetProposalRacingRow, {
    driverName: participant.driverName,
    eventNumber: participant.participantNumber,
    horseName: participant.horseName,
    isCanceled: participant.canceled,
    isDisabled: participant.canceled,
    key: "participant_".concat(participant.participantNumber),
    onToggleOutcome: toggleOutcome,
    outcomes: participant.outcomes,
    participantNumber: participant.participantNumber,
    selectedOutcomes: selectedOutcomes[participant.participantNumber]
  })) : React.createElement("div", null, "Det finns ingen omg\xE5ng att skissa raden p\xE5");
  const renderHeader = () => React.createElement("li", {
    className: "qa-bet-proposal-header translate"
  }, React.createElement("div", {
    className: "left prelrow-column-header"
  }, "MATCH"), React.createElement("div", {
    className: "right prelrow-column-header",
    style: {
      width: 96
    }
  }, "LUTAR \xC5T..."), React.createElement("div", {
    className: "clear"
  }));
  const renderRacingHeader = () => React.createElement(React.Fragment, null, race && meeting && React.createElement("li", {
    className: "qa-bet-proposal-race-summary"
  }, React.createElement(RacePickerItemLight, {
    className: "margin-bottom-1",
    meeting: meeting,
    raceSummary: _objectSpread(_objectSpread({}, race), {}, {
      draws: [draw]
    })
  })), React.createElement("li", {
    className: "qa-bet-proposal-header translate"
  }, React.createElement("div", {
    className: "left prelrow-column-header"
  }, "H\xE4st"), React.createElement("div", {
    className: "right prelrow-column-header",
    style: {
      width: '174'
    }
  }, "LUTAR \xC5T..."), React.createElement("div", {
    className: "clear"
  })));
  const BetProposalHeader = () => {
    if (productId === RACING) {
      return renderRacingHeader();
    } else if (productId !== BOMBEN && productId !== FULLTRAFF && productId !== RACING) {
      return renderHeader();
    }
    return null;
  };
  const betProposalRows = () => {
    switch (productId) {
      case BOMBEN:
        return renderRowsBomben();
      case FULLTRAFF:
        return renderRowsFulltraff();
      case RACING:
        return renderRacing();
      default:
        return renderRows();
    }
  };
  if ((events === null || events === void 0 ? void 0 : events.length) < 1 && productId !== RACING) {
    return React.createElement("div", {
      className: "coupon-create clearfix",
      "data-testid": "bet-proposal-error"
    }, "Det finns ingen omg\xE5ng att skissa raden p\xE5");
  }
  return React.createElement("div", {
    className: "coupon-create clearfix notranslate",
    "data-testid": "marketplace-betproposal-create"
  }, React.createElement("ul", {
    className: "list-group coupon-component ".concat(isLoading ? 'coupon-component-pending' : '')
  }, React.createElement(BetProposalHeader, null), betProposalRows()), React.createElement("div", {
    className: "qa-bet-proposal-footer bet-proposal-footer translate"
  }, React.createElement(RowCounter, {
    className: "margin-right-1",
    eventCount: productId === RACING ? null : events.length,
    productId: productId
    ,
    selectedOutcomes: selectedOutcomes
  }), React.createElement(Button, {
    className: "qa-clear-prel-row-button clear-prel-row-button right",
    onClick: handleClearRow,
    size: 100,
    transparent: true,
    type: "button"
  }, "Rensa")));
};
BetProposalCreate.defaultProps = {
  isDisabled: false,
  isLoading: false,
  selectedOutcomes: {}
};
setGlobal('svs.components.marketplace.betProposal.BetProposalCreate', BetProposalCreate);

 })(window);