(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/bet-proposal/assets/javascripts/bet-proposal-preview.js') >= 0) return;  svs.modules.push('/components/marketplace/bet-proposal/assets/javascripts/bet-proposal-preview.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  useSelector
} = ReactRedux;
const {
  RacePickerItemLight
} = svs.racing;
const {
  resolveProductId
} = svs.utils.products;
const {
  BOMBEN,
  FULLTRAFF,
  RACING
} = svs.utils.products.productIds;
const BetProposalPreview = _ref => {
  let {
    events,
    selectedOutcomes,
    isUpdating,
    isCreating,
    didErrorToCreate,
    didErrorToUpdate,
    participants,
    raceId,
    productName,
    drawNumber,
    showSmall
  } = _ref;
  const {
    BetProposalRow,
    BetProposalBombRow,
    BetProposalFulltraffRow,
    BetProposalRacingRow,
    RowCounter
  } = svs.components.marketplace.betProposal;
  const productId = resolveProductId(productName);
  const race = useSelector(state => state.Races[raceId]);
  const meeting = useSelector(state => race ? state.Meetings[race.meetingId] : undefined);
  const draw = useSelector(state => state.Draws[drawNumber]);
  const renderRacingHeader = () => React.createElement(React.Fragment, null, race && meeting && React.createElement("li", {
    className: "qa-bet-proposal-race-summary"
  }, React.createElement(RacePickerItemLight, {
    className: "margin-bottom-1",
    meeting: meeting,
    raceSummary: _objectSpread(_objectSpread({}, race), {}, {
      draws: [draw]
    })
  })), React.createElement("li", {
    className: "qa-bet-proposal-header translate"
  }, React.createElement("div", {
    className: "left prelrow-column-header"
  }, "H\xE4st"), React.createElement("div", {
    className: "right prelrow-column-header",
    style: {
      width: '174'
    }
  }, "LUTAR \xC5T..."), React.createElement("div", {
    className: "clear"
  })));
  const renderHeader = () => React.createElement("li", {
    className: "qa-bet-proposal-header translate"
  }, React.createElement("div", {
    className: "left prelrow-column-header"
  }, "MATCH"), React.createElement("div", {
    className: "right prelrow-column-header",
    style: {
      width: 96
    }
  }, "LUTAR \xC5T..."), React.createElement("div", {
    className: "clear"
  }));
  const BetProposalRows = () => {
    if (participants && productId === RACING) {
      return participants.map(participant => React.createElement(BetProposalRacingRow, {
        driverName: participant.driverName,
        eventNumber: participant.participantNumber,
        horseName: participant.horseName,
        isCanceled: participant.canceled,
        isDisabled: participant.canceled,
        key: "participant_".concat(participant.participantNumber),
        outcomes: participant.outcomes,
        participantNumber: participant.participantNumber,
        selectedOutcomes: selectedOutcomes[participant.participantNumber]
      }));
    } else if (events && productId === BOMBEN) {
      return events.map(event => React.createElement(BetProposalBombRow, {
        eventComment: event.eventComment,
        eventDescription: event.eventDescription,
        eventNumber: event.eventNumber,
        eventTypeId: event.eventTypeId,
        key: event.eventNumber,
        matchParticipants: event.matchParticipants,
        outcomes: event.outcomes === null ? undefined : event.outcomes,
        participantType: event.participantType,
        selectedOutcomes: selectedOutcomes[event.eventNumber] || {},
        showSmall: showSmall
      }));
    } else if (events && productId === FULLTRAFF) {
      return events.map(event => React.createElement(BetProposalFulltraffRow, {
        eventComment: event.eventComment,
        eventDescription: event.eventDescription,
        eventNumber: event.eventNumber,
        eventTypeId: event.eventTypeId,
        key: event.eventNumber,
        matchParticipants: event.matchParticipants,
        outcomes: event === null || event === void 0 ? void 0 : event.outcomes,
        participantType: event.participantType,
        selectedOutcomes: selectedOutcomes[event.eventNumber],
        showSmall: showSmall
      }));
    }
    return events === null || events === void 0 ? void 0 : events.map(event => React.createElement(BetProposalRow, {
      eventComment: event.eventComment,
      eventDescription: event.eventDescription,
      eventNumber: event.eventNumber,
      eventTypeId: event.eventTypeId,
      key: event.eventNumber,
      outcomes: event.outcomes === null ? undefined : event.outcomes,
      participantType: event.participantType,
      selectedOutcomes: selectedOutcomes[event.eventNumber] || {}
    }));
  };
  return React.createElement("div", {
    className: "coupon-preview notranslate"
  }, React.createElement("ul", {
    className: "list-group coupon-component ".concat(isUpdating || isCreating ? 'coupon-component-pending' : '', " ").concat(didErrorToCreate || didErrorToUpdate ? 'coupon-component-error' : '')
  }, productId === RACING && renderRacingHeader(), productId !== FULLTRAFF && productId !== RACING && renderHeader(), React.createElement(BetProposalRows, null)), React.createElement(RowCounter, {
    className: "right margin-right-1 margin-bottom-2 qa-bet-proposal-footer translate",
    eventCount: productId === RACING ? null : events.length,
    productId: productId,
    selectedOutcomes: selectedOutcomes
  }));
};
BetProposalPreview.defaultProps = {
  didErrorToCreate: false,
  didErrorToUpdate: false,
  events: [],
  isCreating: false,
  isUpdating: false,
  selectedOutcomes: {}
};
setGlobal('svs.components.marketplace.betProposal.BetProposalPreview', BetProposalPreview);

 })(window);