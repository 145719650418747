(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/bet-proposal/assets/javascripts/row-counter.js') >= 0) return;  svs.modules.push('/components/marketplace/bet-proposal/assets/javascripts/row-counter.js');
'use strict';

const {
  buildUniqueRankRows
} = svs.racing.build_unique_rank_row;
const {
  BOMBEN,
  FULLTRAFF,
  RACING
} = svs.utils.products.productIds;
const _calculateRowCountForRacing = _ref => {
  let {
    selectedOutcomes
  } = _ref;
  const mappedRows = [[], [], [], [], []];
  Object.keys(selectedOutcomes).forEach(key => {
    Object.keys(selectedOutcomes[key]).forEach(item => {
      if (item !== undefined && selectedOutcomes[key][item]) {
        mappedRows[Number(item) - 1].push(Number(key));
      }
    });
  });
  const uniqueRacingRows = buildUniqueRankRows(mappedRows, 5);
  return uniqueRacingRows.length;
};
const _calculateRowCountForTipsenAndBomben = _ref2 => {
  let {
    selectedOutcomes,
    eventCount,
    productId
  } = _ref2;
  const isBomben = productId === BOMBEN;
  const allGivenEventChoices = Object.values(selectedOutcomes);
  if (allGivenEventChoices.length !== eventCount) {
    return 0;
  }
  const _checkIfSingleBombenEventChoiceIsIncomplete = eventChoices => !eventChoices || typeof eventChoices !== 'object' || Object.values(eventChoices).length !== 2;
  if (isBomben && allGivenEventChoices.some(_checkIfSingleBombenEventChoiceIsIncomplete)) {
    return 0;
  }
  let count = 1;
  allGivenEventChoices.forEach(eventChoices => {
    if (isBomben) {
      count *= Object.values(eventChoices[0]).filter(Boolean).length;
      count *= Object.values(eventChoices[1]).filter(Boolean).length;
    } else {
      count *= Object.values(eventChoices).filter(Boolean).length;
    }
  });
  return count;
};
const _calculateRowCountForHvs = _ref3 => {
  let {
    selectedOutcomes,
    eventCount
  } = _ref3;
  const allGivenEventChoices = Object.values(selectedOutcomes);
  if (allGivenEventChoices.length !== eventCount) {
    return 0;
  }
  let count = 1;
  allGivenEventChoices.forEach(eventChoices => {
    count *= Object.values(eventChoices).filter(Boolean).length;
  });
  return count;
};
const RowCounter = props => {
  const {
    className,
    selectedOutcomes = {},
    eventCount = null,
    productId
  } = props;
  const calculateRows = () => {
    switch (productId) {
      case FULLTRAFF:
        return _calculateRowCountForHvs({
          selectedOutcomes,
          eventCount
        });
      case RACING:
        return _calculateRowCountForRacing({
          selectedOutcomes
        });
      default:
        return _calculateRowCountForTipsenAndBomben({
          selectedOutcomes,
          eventCount,
          productId
        });
    }
  };
  return React.createElement("div", {
    className: className
  }, "Antal rader:", ' ', React.createElement("span", {
    className: "f-bold"
  }, calculateRows()));
};
setGlobal('svs.components.marketplace.betProposal.RowCounter', RowCounter);

 })(window);